import React from 'react';
import './HeroText.css'

const HeroText = () => {
   
    return (
      <>
            <h2 data-text="InfinityAlgoStation..." className='hero-text'>InfinityAlgoStation...</h2>
           
      </>
    );
};

export default HeroText;